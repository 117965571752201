import React from 'react';
import { connect } from 'react-redux';
import {actions} from "../Redux";
import Button from "./UI/Button";
import {getPopupUser} from "../Containers/Util";
import avatar from "../assets/img/avatar_m.jpeg";

class LikesModal extends React.Component {

    render() {
        const { likesModalIsVisible, toggleVisibilityLikesModal , news} = this.props;
        return (
        <div id="likesModal" className={likesModalIsVisible ? 'modal visible' : 'hidden'}>
            <div className="modal-content width-1000">
                <div className="headline-counter">
                    <span>
                        <i className="fa fa-thumbs-o-up" aria-hidden="true"/> {news ? news.title : 'Likes'}
                    </span>
                </div>
                <div className="content-wrapper">
                    <div className="content-wrapper-box">
                        <div className="grid-wrapper">
                            <div className="small-12 medium-12 large-12">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Benutzer</th>
                                        <th>Datum</th>
                                    </tr>

                                    </thead>
                                    <tbody>
                                    {this.props.likes.length ?
                                        this.props.likes.map(like => {
                                            const likeUser = getPopupUser(this.props.userPopup, like.userId)
                                             return (
                                                 <tr >
                                                     <td className='hoverable-user'>

                                                         <u>{like.userFullName}</u>
                                                         <div
                                                             className="user-info-popup">
                                                             {likeUser.resourceUri ? (
                                                                 <img
                                                                     src={likeUser.resourceUri}
                                                                     alt={likeUser.fullName}
                                                                     className="user-avatar"
                                                                     onClick={(e) => {
                                                                         e.stopPropagation()
                                                                         window.open(likeUser.resourceUri, '_blank')
                                                                     }
                                                                 }
                                                                 />
                                                             ) : (
                                                                 <img src={avatar} alt="avatar" className="user-avatar"/>
                                                             )}
                                                             <div
                                                                 className="user-details">
                                                                 <h4>{likeUser.fullName}</h4>
                                                                 <p>Benutzergruppe: {likeUser.roleLabel}</p>
                                                                 <p>Standort: {likeUser.locationLabel}</p>
                                                             </div>
                                                         </div>
                                                     </td>
                                                     <td><span className="pull-right">{like.dateFormated}</span></td>
                                                 </tr>

                                             )
                                        }) : this.props.loadingAnimation ? <tr>
                                            <td><i
                                                className={'fa fa-spinner fa-pulse fa-fw'}></i></td>
                                            <td></td>
                                        </tr> : <tr>
                                            <td>Noch keine Likes</td>
                                            <td></td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="content-wrapper">
                    <div className="content-wrapper-box">
                        <div className="grid-wrapper">
                            <div className="small-12 medium-12 large-12">
                                <Button label="Schließen" inverted onClick={() => toggleVisibilityLikesModal()}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        )
    }
}

const mapStateToProps = (state) => ({
    likes: state.data.likes,
    loadingAnimation: state.advertising.loadingAnimation,
    userPopup: state.data.userPopup,
});

const mapDispatchToProps = (dispatch) => {
    return {
        loadLikes: (value) => dispatch(actions.Data.likes(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LikesModal);
