import DateInput from 'Components/UI/Form/DateInput';
import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from '../Components/UI/Breadcrumb';
import Button from '../Components/UI/Button';
import { actions } from '../Redux/index';
import { host } from '../Sagas/host';
import * as moment from 'moment';
import heic2any from "heic2any";


const mapStateToProps = (state) => {
    return {
        currentUser: state.data.currentUser,
        advertisingModal: state.data.modal.advertising.advertising,
        advertising: state.advertising.advertising,
        list: state.advertising.list,
        listCount: state.advertising.listCount,
        loadingAnimation: state.advertising.loadingAnimation,
        token: state.ui.token,

    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateModal: (modal) => dispatch(actions.Data.updateModal(modal)),
        updateAdvertising: (advertising) => dispatch(actions.Advertising.updateAdvertising(advertising)),
        deleteAdvertising: (advertising) => dispatch(actions.Advertising.deleteAdvertising(advertising)),
        saveAdvertising: (advertising) => dispatch(actions.Advertising.saveAdvertising(advertising)),
        loadAdvertisingList: (list) => dispatch(actions.Advertising.loadAdvertisingList(list)),
        findArticles: (articles) => dispatch(actions.Data.findArticles(articles)),
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
        updateArticlesFromAdvertising: (value) => dispatch(actions.Data.updateArticlesFromAdvertising(value)),
        updateConfirmModal: (modal) => dispatch(actions.Ui.updateConfirmModal(modal)),
    };
};

class Advertising extends PureComponent {

    constructor() {
        super();
        this.downloadForm = [];
    }


    static propTypes = {
        currentUser: PropTypes.object,
        updateModal: PropTypes.func,
        updateAdvertising: PropTypes.func,
        deleteAdvertising: PropTypes.func,
        saveAdvertising: PropTypes.func,
        loadAdvertisingList: PropTypes.func,
        findArticles: PropTypes.func

    };


    getBase64(file) {
        if (!file) {
            return null;
        }
        if(file.type === 'image/heic' || file.type === 'image/heif'){
            fetch(URL.createObjectURL(file))
                .then(res => res.blob())
                .then(blob => heic2any({
                    blob,
                    toType:"image/jpeg",
                    quality: 0.9
                })).then(conversionResult => {
                let reader = new FileReader();
                reader.readAsDataURL(conversionResult);
                reader.onload = () => {
                    this.props.updateAdvertising({prop:'file', val: reader.result});
                    this.props.updateAdvertising({prop:'name', val: file.name.split('.')[0] + '.jpeg'});
                };
            })
        } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                this.props.updateAdvertising({prop:'file', val: reader.result});
                this.props.updateAdvertising({prop:'name', val: file.name});
            };
        }
    }
    renderFile(file) {
        if (file.indexOf('application/pdf') !== -1) {
            return <embed src={file} width="210" height="297" />;
        } else if (file.indexOf('image/jpeg') !== -1) {
            return <img src={file} width="210" height="auto" alt="" />;
        } else if (file.indexOf('image/jpg') !== -1) {
            return <img src={file} width="210" height="auto" alt="" />;
        } else if (file.indexOf('image/png') !== -1) {
            return <img src={file} width="210" height="auto" alt="" />;
        } else if (file.indexOf('.mp4') !== -1) {
            return <video src={file} width="210" height="auto" controls/>;
        } else {
            return (<i className="fa fa-file-o" width="210" aria-hidden="true" />);
        }
    }


    saveAdvertising(){
        if (!this.checkInput(this.props.advertising.name, 'Wählen Sie ein Dokument zum hochladen')) {
            return;
        }

        if (!this.checkInput(this.props.advertising.title, 'Bitte geben Sie ein Titel ein')) {
            return;
        }

        if (!this.checkInput(this.props.advertising.showFrom, 'Bitte geben Sie das datum ein ab dem die Werbung angezeigt werden soll')) {
            return;
        }

        if (this.props.advertisingModal.action === 'newAdvertising') {
            this.props.saveAdvertising({
                "advertising": {
                    "name": this.props.advertising.name,
                    "articles": this.props.advertising.articles || null,
                    "title": this.props.advertising.title || null,
                     "dateFrom": this.props.advertising.dateFrom || null,
                    "dateTill": this.props.advertising.dateTill || null,
                    "showFrom": this.props.advertising.showFrom || null,
                },
                "file": this.props.advertising.file
            })
        } else if(this.props.advertisingModal.action === 'editAdvertising') {
            this.props.saveAdvertising({
                "advertising": {
                    "__identity": this.props.advertising.id,
                    "name": this.props.advertising.name,
                    "articles": this.props.advertising.articles || null,
                    "title": this.props.advertising.title || null,
                     "dateFrom": this.props.advertising.dateFrom || null,
                    "dateTill": this.props.advertising.dateTill || null,
                    "showFrom": this.props.advertising.showFrom || null,
                },
                "file": this.props.advertising.file
            })
        }
    }


    checkInput(input, alertMessage) {
        if (input) {
            if(input.length > 1) {
                return true;
            } else {
                alert(alertMessage);
                return false;
            }
        } else {
            alert(alertMessage);
            return false;
        }
    }
    triggerFileInput(fileInput) {
        fileInput.click()
    }

    deleteAdvertising(advertising) {
        // const confirmResult = window.confirm('Möchten Sie wirklich die Werbung löschen?');
        // if (confirmResult === true) {
        //     this.props.deleteAdvertising(advertising);
        // }
        this.props.updateConfirmModal({prop: 'visible', value: true})
        this.props.updateConfirmModal({prop: 'title', value: 'Werbung löschen'})
        this.props.updateConfirmModal({prop: 'text', value: 'Möchten Sie wirklich die Werbung löschen?'})
        this.props.updateConfirmModal({prop: 'label', value: "löschen"})
        this.props.updateConfirmModal({prop: 'payload', value: {actionType: 'deleteAdvertising', param: advertising}})
    }

    editAdvertising(advertising) {
        this.props.updateModal({path: 'advertising.advertising', prop: 'view', val: !this.props.advertisingModal.view});
        this.props.updateModal({path: 'advertising.advertising', prop: 'action', val: 'editAdvertising'});
        this.props.updateAdvertising({prop: 'name', val: advertising.name});
        this.props.updateAdvertising({prop: 'articles', val: advertising.articles});
        this.props.updateAdvertising({prop: 'title', val: advertising.title});
        this.props.updateAdvertising({prop: 'id', val: advertising.id});
        this.props.updateAdvertising({prop: 'thumbnailUri', val: advertising.thumbnailUri});
        this.props.updateAdvertising({prop: 'resourceUri', val: advertising.resourceUri});
        this.props.updateAdvertising({prop: 'dateFrom', val: advertising.dateFrom ? moment(advertising.dateFrom).format('YYYY-MM-DD') : null});
        this.props.updateAdvertising({prop: 'dateTill', val: advertising.dateTill ? moment(advertising.dateTill).format('YYYY-MM-DD') : null});
        this.props.updateAdvertising({prop: 'showFrom', val: moment(advertising.showFrom).format('YYYY-MM-DD')});
    }

    getLink(adv) {
        return host + "/download/"+adv.id;
    }

    can (...args) {
        return args.some( permission => this.props.currentUser.permissions.includes(permission))
    }


    render() {
        return (
            <div id="main-side">
                <div id="advertisingModal" className={this.props.advertisingModal.view ? 'modal visible': 'modal hidden'}>
                    <div className="modal-content width-1000">
                        <div className="headline-counter">
                                <span>
        <i className="fa fa-check-square" aria-hidden="true"/> Werbung
                                {this.props.advertising ? (this.props.advertising.id ? ' bearbeiten' : ' anlegen') : ' anlegen'}
                                </span> <span className="close">
                                    <a href="/#-"
                                       onClick={(e) => {
                                           e.preventDefault();
                                           this.props.updateModal({path: 'advertising.advertising', prop: 'view', val: !this.props.advertisingModal.view});
                                           this.props.updateModal({path: 'advertising.advertising', prop: 'action', val: 'newAdvertising'});
                                           this.props.updateAdvertising({prop: 'resetObj', val: ''});
                                       }}
                                    >
                                        <i className="fa fa-times-circle-o"></i>
                                    </a>
                                </span>
                        </div>
                        <form>
                            <div className="content-wrapper content-form">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            <div className="group">
                                                <input
                                                    type="text"
                                                    readOnly
                                                    value={this.props.advertising.name || ''}
                                                    onClick={() => (this.triggerFileInput(this.refs.fileInput))}
                                                />
                                                <label>Datei</label>
                                                <input type="file" ref="fileInput" accept=".pdf, .png, .jpg, .jpeg, .heic, .heif, .mp4, .hevc, .mov" onChange={(e) => {e.preventDefault(); this.getBase64(e.target.files[0]);}} />
                                            </div>
                                            {this.props.advertising.file &&
                                                <div className="FileInput__thumbnail-list">
                                                    <div className="itemPreview">
                                                        <div className="previewItemWrapper">
                                                            {this.renderFile(this.props.advertising.file)}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {!this.props.advertising.file && this.props.advertising.thumbnailUri &&
                                                <div className="FileInput__thumbnail-list">
                                                    <div className="itemPreview">
                                                        <div className="previewItemWrapper">
                                                            <img src={this.props.advertising.thumbnailUri} width="210" height="auto" alt={this.props.advertising.name} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {!this.props.advertising.file && !this.props.advertising.thumbnailUri && this.props.advertising.resourceUri &&
                                                <div className="FileInput__thumbnail-list">
                                                    <div className="itemPreview">
                                                        <div className="previewItemWrapper">

                                                            {this.props.advertising.resourceUri.includes('.mp4') &&
                                                                <video src={this.props.advertising.resourceUri} width="210" height="auto" controls/>
                                                            }
                                                            {!this.props.advertising.resourceUri.includes('.mp4') &&
                                                                <img src={this.props.advertising.resourceUri}
                                                                     width="210" height="auto"
                                                                     alt={this.props.advertising.name}/>
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="small-12 medium-6 large-6">
                                            <div className="group">
                                                <input
                                                    type="text"
                                                    value={this.props.advertising.title || ""}
                                                    onChange={(e) => this.props.updateAdvertising({prop: 'title', val: e.target.value}) }
                                                />
                                                <label>Titel</label>
                                            </div>
                                        </div>
                                        <div className="small-12 medium-6 large-6">
                                            <div className="group">
                                                <DateInput
                                                    selected={this.props.advertising.showFrom
                                                        ? new Date(this.props.advertising.showFrom)
                                                        : null}
                                                    onChange={(date) => this.props.updateAdvertising({
                                                        prop: 'showFrom',
                                                        val: moment(date.toLocaleString('de-DE'), 'DD.MM.YYYY').format('YYYY-MM-DD'),
                                                    })}
                                                />

                                                <label>Anzeigen ab</label>
                                            </div>
                                        </div>
                                        <div className="small-12 medium-6 large-6">
                                            <div className="group">
                                                <DateInput
                                                    selected={this.props.advertising.dateFrom
                                                        ? new Date(this.props.advertising.dateFrom)
                                                        : null}
                                                    onChange={(date) => this.props.updateAdvertising({
                                                        prop: 'dateFrom',
                                                        val: date ? moment(date.toLocaleString('de-DE'), 'DD.MM.YYYY').format('YYYY-MM-DD') : ''
                                                    })}
                                                />

                                                <label>Gültig von</label>
                                            </div>
                                        </div>
                                        <div className="small-12 medium-6 large-6">
                                            <div className="group">
                                                <DateInput
                                                    minDate={new Date(this.props.advertising.dateFrom)}
                                                    selected={this.props.advertising.dateTill
                                                        ? new Date(this.props.advertising.dateTill)
                                                        : null}
                                                    onChange={(date) => this.props.updateAdvertising({
                                                        prop: 'dateTill',
                                                        val: date ? moment(date.toLocaleString('de-DE'), 'DD.MM.YYYY').format('YYYY-MM-DD') : '',
                                                    })}
                                                />

                                                <label>Gültig bis</label>
                                            </div>
                                        </div>
                                        <div className="small-12 medium-12 large-12">
                                            <div className="group">
                                                <textarea value={this.props.advertising.articles || ''} onChange={(e) => this.props.updateAdvertising({prop: 'articles', val: e.target.value})}/>
                                                <label>Artikel</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-wrapper">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                                <Button
                                                    label={ this.props.loadingAnimation ? 'upload ...' :
                                                        (this.props.advertising.id ? 'Änderungen Speichern' :'Hochladen')
                                                    }
                                                    inverted
                                                    disabled={this.props.loadingAnimation}
                                                    onClick={() => this.saveAdvertising()}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Breadcrumb path="Werbung">
                    {this.can('werbung_erstellen') &&
                    <Button label="Neue Werbung"
                        onClick={() => {
                            this.props.updateModal({
                                path: 'advertising.advertising',
                                prop: 'view',
                                val: !this.props.advertisingModal.view,
                            });
                            this.props.updateAdvertising({prop: 'resetObj', val: ''});
                        }}
                    />}
                </Breadcrumb>

                <main>
                    <div className="content-wrapper inner-ver">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                <div className="small-12 medium-12 large-12">
                                    <div className="site-wrapper">
                                        <div className="headline-counter">
                                            <span><i className="fa fa-news" aria-hidden="true"/> Werbungen  {this.props.loadingAnimation && <i className="fa fa-spinner fa-pulse fa-fw" aria-hidden="true"></i>}</span>

                                        </div>
                                        {this.props.list.map((advertising, index) => (
                                            <div className="news-bild" key={index}>
                                                {
                                                    advertising.thumbnailUri &&
                                                    <div className="img-wrapper">
                                                        <form action={this.getLink(advertising)} target="_blank"  method="post" ref={(ref) => { this.downloadForm[index] = ref }}>
                                                            <input type="hidden" name="authTok" value={'Bearer ' + this.props.token}  />
                                                        </form>
                                                        <a href="/" onClick={(e) => {e.preventDefault(); this.downloadForm[index].submit()}}>
                                                            <img src={advertising.thumbnailUri} alt={advertising.name}/>
                                                        </a>
                                                    </div>
                                                }
                                                {
                                                    advertising.resourceUri && !advertising.thumbnailUri &&
                                                    <div className="img-wrapper">
                                                        <form action={this.getLink(advertising)} target="_blank"  method="post" ref={(ref) => { this.downloadForm[index] = ref }}>
                                                            <input type="hidden" name="authTok" value={'Bearer ' + this.props.token}  />
                                                        </form>
                                                        <a href="/" onClick={(e) => {e.preventDefault(); this.downloadForm[index].submit()}}>
                                                            {advertising.resourceUri.includes('.mp4') &&
                                                                <video src={advertising.resourceUri} width="210" height="auto" controls />
                                                            }
                                                            {!advertising.resourceUri.includes('.mp4') &&
                                                                <img src={advertising.resourceUri} alt={advertising.name}/>
                                                            }
                                                        </a>
                                                    </div>
                                                }
                                                <div className="news-bild-content">
                                                    <span className="headline-news">{advertising.title} {advertising.dateFromFormated ? '| gültig von ' + advertising.dateFromFormated : ''} {advertising.dateTillFormated ? 'bis ' + advertising.dateTillFormated : ''}</span>
                                                    {/* advertising.articles &&
                                                        <p>
                                                            <a href="/"
                                                               onClick={(e) => {
                                                                   e.preventDefault();
                                                                   this.props.findArticles(advertising.articles);
                                                                   this.props.updateArticlesFromAdvertising(advertising.id);
                                                                   this.props.updateView('StockPage');
                                                               }}
                                                            >Artikel anzeigen</a>
                                                        </p>
                                                            */}

                                                </div>
                                                <div className="delete-button visible">
                                                    {this.can('werbung_bearbeiten') && <>
                                                        <i onClick={() => this.editAdvertising(advertising)}
                                                           className={'fa fa-2x fa-pencil'}
                                                        /> &nbsp;
                                                    </>}
                                                    {this.can('werbung_l_schen') && <i onClick={() => this.deleteAdvertising(advertising)}
                                                        className={'fa fa-2x fa-trash'}
                                                    />}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        this.props.listCount > this.props.list.length &&
                        <div className="content-wrapper inner-ver">
                            <div className="content-wrapper-box">
                                <div className="grid-wrapper">
                                    <div className="small-12 medium-12 large-12">
                                        <Button
                                            label="Weitere Werbungen anzeigen"
                                            inverted
                                            onClick={() => {
                                                this.props.loadAdvertisingList({'from': this.props.list.length});
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </main>

                <footer id="footer-main">
                </footer>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Advertising);
