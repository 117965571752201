import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemBody,
    AccordionItemTitle,
} from 'react-accessible-accordion';
import { Doughnut } from 'react-chartjs-2';
import { connect } from 'react-redux';
import Breadcrumb from '../Components/UI/Breadcrumb';
import Button from '../Components/UI/Button';
import Card from '../Components/UI/Card/Card';
import { actions } from '../Redux/index';
import { getTaskPriorityClass } from './Util';
import ReactHtmlParser from 'react-html-parser';
import { getPopupUser } from './Util';
import avatar from '../assets/img/avatar_m.jpeg';

const mapStateToProps = (state) => {
    return {
        taskList: state.task.list,
        documentList: state.data.documentList,
        listCount: state.news.listCount,
        newsList: state.news.list,
        currentUser: state.data.currentUser,
        taskListNotFinishedCount: state.task.listNotFinishedCount,
        currentHolidaysOnDashboard: state.ui.currentHolidaysOnDashboard,
        token: state.ui.token,
        userTrainings: state.training.userTrainings,
        newsCategories: state.news.categories,
        userPopup: state.data.userPopup,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateTaskList: (task) => dispatch(actions.Data.updateTaskList(task)),
        updateNewsList: (news) => dispatch(actions.Data.updateNewsList(news)),
        loadNewsList: (list) => dispatch(actions.News.loadNewsList(list)),
        updateView: (value) => dispatch(actions.Ui.updateView(value)),
        loadAllUsers: (value) => dispatch(actions.Data.loadAllUsers(value)),
        updateCurrentNews: (singleNewsObject) =>
            dispatch(actions.Data.updateCurrentNews(singleNewsObject)),
        addTaskFinished: (task) => dispatch(actions.Task.addTaskFinished(task)),
        updateTask: (task) => dispatch(actions.Task.updateTask(task)),
        updateTaskListNotFinishedCount: (value) =>
            dispatch(actions.Task.updateTaskListNotFinishedCount(value)),
        updateAbsenceView: (value) => dispatch(actions.Absence.updateView(value)),
        loadAbsenceList: (list) => dispatch(actions.Absence.loadAbsenceList(list)),
        updateAbsenceList: (list) => dispatch(actions.Absence.updateAbsenceList(list)),
        updateCurrentHolidaysOnDashboard: (value) =>
            dispatch(actions.Ui.updateCurrentHolidaysOnDashboard(value)),
        updateStockFilter: (filter) => dispatch(actions.Data.updateStockFilter(filter)),
        loadUserTrainings: (value) => dispatch(actions.Training.loadUserTrainings(value)),
        loadTrainingList: (list) => dispatch(actions.Training.loadTrainingList(list)),
        loadAllUsersForTraining: (list) => dispatch(actions.Training.loadAllUsersForTraining(list)),
        updateVacationMenuClass: (value) => dispatch(actions.Ui.updateVacationMenuClass(value)),
        updateReference: (value) => dispatch(actions.Training.updateReference(value)),
        updateOldListView: (value) => dispatch(actions.Training.updateOldListView(value)),
        updateValuationReference: (value) =>
            dispatch(actions.Training.updateValuationReference(value)),
        loadCurrentFolder: (folder) => dispatch(actions.Data.loadCurrentFolder(folder)),
    };
};

class Dashboard extends PureComponent {
    static propTypes = {
        updateView: PropTypes.func.isRequired,
        taskList: PropTypes.array,
        subTaskList: PropTypes.array,
        updateTaskList: PropTypes.func,
        updateNewsList: PropTypes.func,
        documentList: PropTypes.array,
        updateCurrentNews: PropTypes.func,
        updateTask: PropTypes.func,
        addTaskFinished: PropTypes.func,
        updateTaskListNotFinishedCount: PropTypes.func,
        holidays: PropTypes.object,
        currentHolidaysOnDashboard: PropTypes.object,
        updateCurrentHolidaysOnDashboard: PropTypes.func.isRequired,
        newsCategories: PropTypes.array,
    };

    cropText(text) {
        return text.length > 150 ? text.substring(0, 150) + '...' : text.substring(0, 150);
    }

    componentDidMount() {
        const url = window.location.href; // Get the current URL
        const keyword = '/slf/';

        // Check if URL contains '/slf/'
        if (url.includes(keyword)) {
            // Extract the part after '/slf/'
            //const extractedPart = url.split(keyword)[1];
            this.props.updateView('Documents');
        }

        this.props.updateCurrentHolidaysOnDashboard({
            val:
                this.props.currentUser.availableHolidays -
                this.props.currentUser.holidaysTakenThisYear -
                this.props.currentUser.holidaysPlannedThisYear,
            label: 'übrig',
        });
        this.props.loadUserTrainings(true);
        this.props.loadNewsList({ from: 0, view: 'dashboard' });
    }

    getDougnutData() {
        const availableHolidays = this.props.currentUser.availableHolidays;
        const dashboard = this.props.currentHolidaysOnDashboard.val;
        return [(100 * dashboard) / availableHolidays, 100 - (100 * dashboard) / availableHolidays];
    }

    can(...args) {
        return args.some((permission) => this.props.currentUser.permissions.includes(permission));
    }

    render() {
        let newNewsAllgemein = 0,
            newNewsPersonal = 0,
            newNewsSortiment = 0;
        const data = {
            datasets: [
                {
                    data: this.getDougnutData(),
                    backgroundColor: ['rgba(226,4,20,1)', 'rgba(29,29,27,1)'],
                },
            ],
        };
        const options = {
            cutoutPercentage: 82,

            tooltips: {
                enabled: false,
            },
        };

        return (
            <div id="main-side">
                <Breadcrumb path="Dashboard" />
                <main className="pt-30">
                    <div className="content-wrapper">
                        <div className="content-wrapper-box">
                            <div className="grid-wrapper">
                                {this.can('aufgaben_erhalten') && (
                                    <Card
                                        title="Aufgaben"
                                        halfWidth={true}
                                        className="check-list"
                                        icon="task"
                                        header_extra={
                                            <span className="counter">
                                                {this.props.taskListNotFinishedCount}
                                            </span>
                                        }
                                        footer={
                                            <Button
                                                label="Alle Aufgaben"
                                                inverted={true}
                                                onClick={() => this.props.updateView('Task')}
                                            />
                                        }
                                    >
                                        <div className="check-list-main">
                                            {this.props.taskList.length !== 0 &&
                                                this.props.taskList.map((task, index) => {
                                                    if (index < 5) {
                                                        if (task.id === 'defaultId') {
                                                            return (
                                                                <div
                                                                    className="checkbox-wrapper"
                                                                    key={index}
                                                                >
                                                                    <a
                                                                        href="/#-"
                                                                        className="dokument-item"
                                                                        key={index}
                                                                    >
                                                                        <div className="default-list-item">
                                                                            <span />
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            );
                                                        } else {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className={getTaskPriorityClass(
                                                                        task
                                                                    )}
                                                                >
                                                                    <label>
                                                                        {!task.locked && (
                                                                            <span
                                                                                className={
                                                                                    task.checked ===
                                                                                    true
                                                                                        ? 'boxChecked'
                                                                                        : 'boxUnchecked'
                                                                                }
                                                                                onClick={() => {
                                                                                    this.props.updateTask(
                                                                                        {
                                                                                            id:
                                                                                                task.id,
                                                                                            prop:
                                                                                                'checked',
                                                                                            val: !task.checked,
                                                                                        }
                                                                                    );
                                                                                    setTimeout(
                                                                                        () => {
                                                                                            if (
                                                                                                task.checked
                                                                                            ) {
                                                                                                this.props.updateTask(
                                                                                                    {
                                                                                                        id:
                                                                                                            task.id,
                                                                                                        prop:
                                                                                                            'completed',
                                                                                                        val: true,
                                                                                                    }
                                                                                                );
                                                                                                this.props.addTaskFinished(
                                                                                                    task
                                                                                                );
                                                                                                this.props.updateTaskListNotFinishedCount(
                                                                                                    this
                                                                                                        .props
                                                                                                        .taskListNotFinishedCount -
                                                                                                        1
                                                                                                );
                                                                                            }
                                                                                        },
                                                                                        2000
                                                                                    );
                                                                                }}
                                                                            />
                                                                        )}
                                                                        {task.locked && (
                                                                            <span
                                                                                className={
                                                                                    'boxUnchecked locked'
                                                                                }
                                                                            />
                                                                        )}
                                                                    </label>
                                                                    <div className="checkbox-text">
                                                                        <span
                                                                            className={'prim-text'}
                                                                            style={{
                                                                                cursor: task.link
                                                                                    ? 'pointer'
                                                                                    : 'unset',
                                                                            }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                if (
                                                                                    task.link ===
                                                                                    'absenceView 1'
                                                                                ) {
                                                                                    this.props.updateVacationMenuClass(
                                                                                        'has-subnav open-submenu active'
                                                                                    );

                                                                                    this.props.updateView(
                                                                                        'Absence'
                                                                                    );
                                                                                    this.props.updateAbsenceView(
                                                                                        '1'
                                                                                    );
                                                                                    this.props.updateAbsenceList(
                                                                                        {
                                                                                            list: [],
                                                                                            reset: true,
                                                                                        }
                                                                                    );
                                                                                    this.props.loadAbsenceList(
                                                                                        {
                                                                                            from: 0,
                                                                                            view: 1,
                                                                                            lazy: false,
                                                                                        }
                                                                                    );
                                                                                } else if (
                                                                                    task.link ===
                                                                                    'absenceView 2'
                                                                                ) {
                                                                                    this.props.updateVacationMenuClass(
                                                                                        'has-subnav open-submenu active'
                                                                                    );

                                                                                    this.props.updateView(
                                                                                        'Absence'
                                                                                    );
                                                                                    this.props.updateAbsenceView(
                                                                                        '2'
                                                                                    );
                                                                                    this.props.updateAbsenceList(
                                                                                        {
                                                                                            list: [],
                                                                                            reset: true,
                                                                                        }
                                                                                    );
                                                                                    this.props.loadAbsenceList(
                                                                                        {
                                                                                            from: 0,
                                                                                            view: 2,
                                                                                            lazy: false,
                                                                                        }
                                                                                    );
                                                                                } else if (
                                                                                    task.link ===
                                                                                    'Training'
                                                                                ) {
                                                                                    this.props.updateView(
                                                                                        'Training'
                                                                                    );
                                                                                    this.props.updateReference(
                                                                                        task.reference
                                                                                    );
                                                                                } else if (
                                                                                    task.link ===
                                                                                    'Valuation'
                                                                                ) {
                                                                                    this.props.updateView(
                                                                                        'Training'
                                                                                    );
                                                                                    this.props.updateReference(
                                                                                        task.reference
                                                                                    );
                                                                                    this.props.updateOldListView(
                                                                                        true
                                                                                    );
                                                                                    this.props.updateValuationReference(
                                                                                        true
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            {task.title}{' '}
                                                                            {task.createdByFullName
                                                                                ? ` : (${task.createdByFullName} | ${task.createdAtFormated})`
                                                                                : ''}
                                                                        </span>
                                                                        <span
                                                                            className={'sec-text'}
                                                                        >
                                                                            {task.deadlineFormated ===
                                                                            ''
                                                                                ? ''
                                                                                : 'Fällig: ' +
                                                                                  task.deadlineFormated}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    }
                                                    return null;
                                                })}
                                            {!this.props.taskList.length && (
                                                <div className="checkbox-wrapper">
                                                    <div className="checkbox-text">
                                                        <span className="prim-text">
                                                            Keine neuen Aufgaben
                                                        </span>
                                                        <span className="sec-text" />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </Card>
                                )}
                                {this.can('neuigkeiten_eigene_sehen', 'neuigkeiten_alle_sehen') && (
                                    <Card
                                        title="Neuigkeiten"
                                        icon="news"
                                        className="news-list overflow-visible"
                                        halfWidth={true}
                                        footer={
                                            <Button
                                                label="Alle Neuigkeiten"
                                                inverted={true}
                                                onClick={() => this.props.updateView('News')}
                                            />
                                        }
                                    >
                                        <Accordion>
                                            {this.props.newsCategories.map((cat, index) => (
                                                <AccordionItem
                                                    key={cat.label + 'accordionItem'}
                                                    expanded={index + 1 === 1}
                                                >
                                                    <AccordionItemTitle>
                                                        {cat.label}
                                                    </AccordionItemTitle>
                                                    <AccordionItemBody
                                                        style={{ overflow: 'visible' }}
                                                    >
                                                        <div className="check-list-main">
                                                            {this.props.newsList
                                                                .filter(
                                                                    (n) => n.category === cat.label
                                                                )

                                                                .map((news, index) => {
                                                                    if (
                                                                        news.category ===
                                                                        'Allgemein'
                                                                    ) {
                                                                        newNewsAllgemein++;
                                                                    } else if (
                                                                        news.category === 'Personal'
                                                                    ) {
                                                                        newNewsPersonal++;
                                                                    } else if (
                                                                        news.category ===
                                                                        'Sortiment'
                                                                    ) {
                                                                        newNewsSortiment++;
                                                                    }
                                                                    if (index < 3) {
                                                                        if (
                                                                            news.id === 'defaultId'
                                                                        ) {
                                                                            return (
                                                                                <div
                                                                                    className="news-short"
                                                                                    key={index}
                                                                                >
                                                                                    <a
                                                                                        href="/#-"
                                                                                        className="dokument-item"
                                                                                        key={index}
                                                                                    >
                                                                                        <div className="default-list-item">
                                                                                            <span></span>
                                                                                        </div>
                                                                                    </a>
                                                                                </div>
                                                                            );
                                                                        } else {
                                                                            const newsUser = getPopupUser(
                                                                                this.props
                                                                                    .userPopup,
                                                                                news.createdById
                                                                            );
                                                                            return (
                                                                                <div
                                                                                    className="news-short"
                                                                                    key={index}
                                                                                >
                                                                                    <span>
                                                                                        {news.dateFormated ||
                                                                                            news.date}{' '}
                                                                                        &nbsp;
                                                                                        {news.createdBy && (
                                                                                            <u className="hoverable-user">
                                                                                                von{' '}
                                                                                                {
                                                                                                    news.createdBy
                                                                                                }
                                                                                                <div className="user-info-popup-right">
                                                                                                    {newsUser.resourceUri ? (
                                                                                                        <img
                                                                                                            src={
                                                                                                                newsUser.resourceUri
                                                                                                            }
                                                                                                            alt={
                                                                                                                newsUser.fullName
                                                                                                            }
                                                                                                            className={
                                                                                                                index >=
                                                                                                                2
                                                                                                                    ? 'user-avatar'
                                                                                                                    : 'user-avatar-top'
                                                                                                            }
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) => {
                                                                                                                e.stopPropagation();
                                                                                                                window.open(
                                                                                                                    newsUser.resourceUri,
                                                                                                                    '_blank'
                                                                                                                );
                                                                                                            }}
                                                                                                        />
                                                                                                    ) : (
                                                                                                        <img
                                                                                                            src={
                                                                                                                avatar
                                                                                                            }
                                                                                                            alt="avatar"
                                                                                                            className={
                                                                                                                index >=
                                                                                                                2
                                                                                                                    ? 'user-avatar'
                                                                                                                    : 'user-avatar-top'
                                                                                                            }
                                                                                                        />
                                                                                                    )}
                                                                                                    <div className="user-details">
                                                                                                        <h4>
                                                                                                            {
                                                                                                                newsUser.fullName
                                                                                                            }
                                                                                                        </h4>
                                                                                                        <p>
                                                                                                            Benutzergruppe:{' '}
                                                                                                            {
                                                                                                                newsUser.roleLabel
                                                                                                            }
                                                                                                        </p>
                                                                                                        <p>
                                                                                                            Standort:{' '}
                                                                                                            {
                                                                                                                newsUser.locationLabel
                                                                                                            }
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </u>
                                                                                        )}
                                                                                    </span>
                                                                                    <span className="news-short-title">
                                                                                        {news.title}
                                                                                    </span>
                                                                                    <div className="news-short-main">
                                                                                        {ReactHtmlParser(
                                                                                            this.cropText(
                                                                                                news.text
                                                                                            )
                                                                                        )}
                                                                                    </div>
                                                                                    <a
                                                                                        href="/#-"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            e.preventDefault();
                                                                                            this.props.updateCurrentNews(
                                                                                                news
                                                                                            );
                                                                                            this.props.updateView(
                                                                                                'CurrentNews'
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        Mehr lesen
                                                                                    </a>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    }
                                                                    return null;
                                                                })}
                                                        </div>
                                                        {newNewsAllgemein === 0 &&
                                                            cat.label === 'Allgemein' && (
                                                                <div className="noNewsFound">
                                                                    <p>
                                                                        Keine Neuigkeiten vorhanden
                                                                    </p>
                                                                </div>
                                                            )}
                                                        {newNewsPersonal === 0 &&
                                                            cat.label === 'Personal' && (
                                                                <div className="noNewsFound">
                                                                    <p>
                                                                        Keine Neuigkeiten vorhanden
                                                                    </p>
                                                                </div>
                                                            )}
                                                        {newNewsSortiment === 0 &&
                                                            cat.label === 'Sortiment' && (
                                                                <div className="noNewsFound">
                                                                    <p>
                                                                        Keine Neuigkeiten vorhanden
                                                                    </p>
                                                                </div>
                                                            )}
                                                    </AccordionItemBody>
                                                </AccordionItem>
                                            ))}
                                        </Accordion>
                                    </Card>
                                )}
                                {this.can('urlaub_menu_sehen') && (
                                    <Card
                                        title="Mein Urlaub"
                                        icon="vacation"
                                        className="VacationWidget"
                                    >
                                        <div className="VacationWidget-inline">
                                            <div className="VacationWidget__info">
                                                <ul>
                                                    <li
                                                        className="VacationWidget__item"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.updateCurrentHolidaysOnDashboard(
                                                                {
                                                                    val: this.props.currentUser
                                                                        .availableHolidays,
                                                                    label: 'gesamt',
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        Urlaubstage gesamt:{' '}
                                                        <span>
                                                            {' '}
                                                            {this.props.currentUser
                                                                .availableHolidays || 0}
                                                        </span>
                                                    </li>
                                                    <li
                                                        className="VacationWidget__item"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.updateCurrentHolidaysOnDashboard(
                                                                {
                                                                    val: this.props.currentUser
                                                                        .holidaysTakenThisYear,
                                                                    label: 'genommen',
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        Genutzte Tage:{' '}
                                                        <span>
                                                            {' '}
                                                            {
                                                                this.props.currentUser
                                                                    .holidaysTakenThisYear
                                                            }
                                                        </span>
                                                    </li>
                                                    <li
                                                        className="VacationWidget__item"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.updateCurrentHolidaysOnDashboard(
                                                                {
                                                                    val: this.props.currentUser
                                                                        .holidaysPlannedThisYear,
                                                                    label: 'geplant',
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        Geplante Tage:{' '}
                                                        <span>
                                                            {' '}
                                                            {
                                                                this.props.currentUser
                                                                    .holidaysPlannedThisYear
                                                            }
                                                        </span>
                                                    </li>
                                                    <li
                                                        className="VacationWidget__item"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            this.props.updateCurrentHolidaysOnDashboard(
                                                                {
                                                                    val:
                                                                        this.props.currentUser
                                                                            .availableHolidays -
                                                                        this.props.currentUser
                                                                            .holidaysTakenThisYear -
                                                                        this.props.currentUser
                                                                            .holidaysPlannedThisYear,
                                                                    label: 'übrig',
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        Offene Tage:{' '}
                                                        <span>
                                                            {' '}
                                                            {this.props.currentUser
                                                                .availableHolidays -
                                                                this.props.currentUser
                                                                    .holidaysTakenThisYear -
                                                                this.props.currentUser
                                                                    .holidaysPlannedThisYear}
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="VacationWidget__chart">
                                                <span className="circle">
                                                    <div className="ProfilOverview">
                                                        <div className="profil-col">
                                                            <div className="profil-col-content">
                                                                <div className="profil-status">
                                                                    <div className="profil-status-text-wrapper">
                                                                        <div className="value">
                                                                            {
                                                                                this.props
                                                                                    .currentHolidaysOnDashboard
                                                                                    .val
                                                                            }
                                                                        </div>
                                                                        <div className="text">
                                                                            {this.props
                                                                                .currentHolidaysOnDashboard
                                                                                .val === 1
                                                                                ? 'Offener Tag'
                                                                                : 'Offene Tage'}{' '}
                                                                        </div>
                                                                    </div>
                                                                    <Doughnut
                                                                        id="profil-status-chart"
                                                                        data={data}
                                                                        options={options}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </Card>
                                )}

                                {this.can('schulung_sehen') && (
                                    <Card
                                        title="Meine Veranstaltungen"
                                        icon="training"
                                        className="news-list"
                                        halfWidth={!this.can('urlaub_menu_sehen')}
                                        footer={
                                            <Button
                                                label="Alle Veranstaltungen"
                                                inverted={true}
                                                onClick={() => {
                                                    this.props.updateView('Training');
                                                    this.props.loadTrainingList({
                                                        old: false,
                                                        from: 0,
                                                    });
                                                    this.props.loadTrainingList({
                                                        old: true,
                                                        from: 0,
                                                    });
                                                    this.props.loadAllUsersForTraining(true);
                                                }}
                                            />
                                        }
                                    >
                                        <div className="check-list-main">
                                            {this.props.userTrainings
                                                .filter((t) => new Date(t.date) > new Date())
                                                .map((training, index) => (
                                                    <div className="news-short" key={index}>
                                                        <span className="time">
                                                            {training.dateFormated}
                                                        </span>
                                                        <span className="news-short-main">
                                                            {training.title}
                                                        </span>
                                                    </div>
                                                ))}
                                        </div>
                                    </Card>
                                )}
                                {this.can('schulung_sehen') && (
                                    <Card
                                        title="Meine Vergangenen Veranstaltungen"
                                        icon="training"
                                        className="news-list"
                                        halfWidth={!this.can('urlaub_menu_sehen')}
                                        footer={
                                            <Button
                                                label="Alle Veranstaltungen"
                                                inverted={true}
                                                onClick={() => {
                                                    this.props.updateView('Training');
                                                    this.props.loadTrainingList({
                                                        old: false,
                                                        from: 0,
                                                    });
                                                    this.props.loadTrainingList({
                                                        old: true,
                                                        from: 0,
                                                    });
                                                    this.props.loadAllUsersForTraining(true);
                                                }}
                                            />
                                        }
                                    >
                                        <div className="check-list-main">
                                            {this.props.userTrainings
                                                .filter((t) => new Date(t.date) < new Date())
                                                .map((training, index) => (
                                                    <div className="news-short" key={index}>
                                                        <span className="time">
                                                            {training.dateFormated}
                                                        </span>
                                                        <span className="news-short-main">
                                                            {training.title}
                                                        </span>
                                                    </div>
                                                ))}
                                        </div>
                                    </Card>
                                )}
                            </div>
                        </div>
                    </div>
                </main>

                <footer id="footer-main"></footer>
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);
