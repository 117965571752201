import ClaimForm from 'Components/Claims/ClaimForm';
import Button from 'Components/UI/Button';
import Card from 'Components/UI/Card/Card';
import CardGrid from 'Components/UI/Card/CardGrid';
import FormInput from 'Components/UI/Form/FormInput';
import Icon from 'Components/UI/Icon/Icon';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { actions, selectors } from '../../Redux';
import './claims.scss';
import IconLink from '../UI/IconLink';
import { host } from '../../Sagas/host';
import { getPopupUser } from '../../Containers/Util';
import avatar from '../../assets/img/avatar_m.jpeg';

class ClaimList extends React.Component {
    static propTypes = {
        currentUser: PropTypes.object,
        openClaims: PropTypes.array,
        completedClaims: PropTypes.array,
        reloadOpenClaims: PropTypes.func,
        LoadMoreOpenClaims: PropTypes.func,
        reloadCompletedClaims: PropTypes.func,
        LoadMoreCompletedClaims: PropTypes.func,
        locationFilter: PropTypes.string,
        onEdit: PropTypes.func,
    };

    // state = {
    //     showForm: false,
    //     claimToEdit: null,
    //     filter: { number: '', forceReload: false},
    // };

    constructor() {
        super();
        this.state = {
            showForm: false,
            claimToEdit: null,
            filter: { number: '', forceReload: false },
            scrollTop: 0,
        };
        this.timer = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.locationFilter !== this.props.locationFilter) {
            this.props.reloadOpenClaims({ ...this.state.filter, forceReload: true });
            this.props.reloadCompletedClaims({ ...this.state.filter, forceReload: true });
        }
    }

    componentDidMount() {
        this.props.reloadOpenClaims({ ...this.state.filter, forceReload: true });
        this.props.reloadCompletedClaims({ ...this.state.filter, forceReload: true });
    }

    closeForm = () => {
        this.setState({
            showForm: false,
            claimToEdit: null,
        });
    };
    repositionAfterSort = (position) => {
        // this.setState({...this.state, scrollTop: position})
        // window.scrollTo(0, this.state.scrollTop);
    };

    handleNumberFilterChange = (newNumberFilter) => {
        this.setState({ filter: { number: newNumberFilter } });
        //if ((newNumberFilter.length > this.state.filter.number.length || newNumberFilter.length === 0)
        //     || (this.state.filter.number.substring(0, newNumberFilter.length) !== newNumberFilter)) {
        this.props.reloadOpenClaims({ number: newNumberFilter, forceReload: true });
        this.props.reloadCompletedClaims({ number: newNumberFilter, forceReload: true });
        //}
    };

    getLink() {
        let location = null;
        if (this.props.locationFilter) {
            location = this.props.locationFilter;
        }
        const yearRegex = /(?:(?:18|19|20|21)[0-9]{2})/g;
        let yearFilter = '';
        if (yearRegex.test(this.props.yearFilter)) {
            yearFilter = `?year=${this.props.yearFilter}`;
        }

        return `${host}/exportclaim/${location}${yearFilter}`;
    }

    handleEdit = (claim = null) => {
        this.setState({
            showForm: true,
            claimToEdit: (claim && claim.id) || null,
        });
    };

    showSorting(column) {
        if (this.props.sorting.includes(column)) {
            return <i className="fa fa-angle-up" />;
        }
        if (this.props.sorting.includes(`-${column}`)) {
            return <i className="fa fa-angle-down" />;
        }
    }
    updateSorting(prop) {
        this.props.updateSorting(prop);
        this.props.reloadOpenClaims({ ...this.state.filter, forceReload: true });
        this.props.reloadCompletedClaims({ ...this.state.filter, forceReload: true });
    }

    can(...args) {
        return args.some((permission) => this.props.currentUser.permissions.includes(permission));
    }

    renderClaims = (claims) => {
        const emptyStyle = { minWidth: '100px' };
        if (claims.length === 0 && !this.props.loadAnimation.value) {
            return (
                <tr>
                    <td colSpan={6}>Keine Beanstandungen gefunden.</td>
                </tr>
            );
        }
        if (this.props.loadAnimation.value === true) {
            return (
                <>
                    {[...Array(25).keys()].map((row) => (
                        <tr>
                            <td>
                                <div className="default-list-item">
                                    <span style={emptyStyle} />
                                </div>
                            </td>
                            <td>
                                <div className="default-list-item">
                                    <span style={emptyStyle} />
                                </div>
                            </td>
                            <td>
                                <div className="default-list-item">
                                    <span style={emptyStyle} />
                                </div>
                            </td>
                            <td>
                                <div className="default-list-item">
                                    <span style={emptyStyle} />
                                </div>
                            </td>
                            <td>
                                <div className="default-list-item">
                                    <span style={emptyStyle} />
                                </div>
                            </td>
                            <td>
                                <div className="default-list-item">
                                    <span style={emptyStyle} />
                                </div>
                            </td>
                            <td>
                                <div className="default-list-item">
                                    <span style={emptyStyle} />
                                </div>
                            </td>
                            <td>
                                <div className="default-list-item">
                                    <span style={emptyStyle} />
                                </div>
                            </td>
                        </tr>
                    ))}
                </>
            );
        }
        return (
            claims
                //.sort((a, b) => b.number.localeCompare(a.number))
                .sort((a, b) => {
                    let sortProp = this.props.sorting[0] || 'createdAt';
                    const sortPropWithoutSign = sortProp.replace('-', '');
                    let propa = a[sortPropWithoutSign];
                    let propb = b[sortPropWithoutSign];
                    if (propa > propb) {
                        return sortProp[0] === '-' ? -1 : 1;
                    } else if (propa < propb) {
                        return sortProp[0] === '-' ? 1 : -1;
                    }
                    return 0;
                })
                // .filter(claim => {
                //     return claim.number.toLowerCase().includes(this.state.filter.number.toLowerCase()) ||
                //     claim.customerName.toLowerCase().includes(this.state.filter.number.toLowerCase()) ||
                //     claim.supplierName.toLowerCase().includes(this.state.filter.number.toLowerCase()) ||
                //     claim.createdAtFormated.toLowerCase().includes(this.state.filter.number.toLowerCase()) ||
                //     claim.haulerName.toLowerCase().includes(this.state.filter.number.toLowerCase()) ||
                //      this.state.filter.number.split(' ').includes(claim.number.toLowerCase()) ||
                //      this.state.filter.number.split(' ').includes(claim.customerName.toLowerCase()) ||
                //      this.state.filter.number.split(' ').includes(claim.supplierName.toLowerCase()) ||
                //      this.state.filter.number.split(' ').includes(claim.haulerName.toLowerCase())
                //
                // })
                .map((claim, index) => this.renderRow(claim, index))
        );
    };

    renderRow = (claim, index) => {
        const claimCreator = getPopupUser(this.props.userPopup, claim.createdById);
        return (
            <tr key={claim.id} onClick={() => this.handleEdit(claim)}>
                <td>{claim.number}</td>
                <td>
                    {claim.createdAtFormated}
                    {/*
                    <time dateTime={claim.createdAt}>
                        {new Date(claim.createdAt).toLocaleDateString()}
                    </time>
                */}
                </td>
                <td className="hoverable-user">
                    <u>{claim.createdBy}</u>
                    {claimCreator.fullName && (
                        <div className="user-info-popup">
                            {claimCreator.resourceUri ? (
                                <img
                                    src={claimCreator.resourceUri}
                                    alt={claimCreator.fullName}
                                    className={index >= 4 ? 'user-avatar' : 'user-avatar-top'}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        window.open(claimCreator.resourceUri, '_blank');
                                    }}
                                />
                            ) : (
                                <img
                                    src={avatar}
                                    alt="avatar"
                                    className={index >= 4 ? 'user-avatar' : 'user-avatar-top'}
                                />
                            )}
                            <div className="user-details">
                                <h4>{claimCreator.fullName}</h4>
                                <p>Benutzergruppe: {claimCreator.roleLabel}</p>
                                <p>Standort: {claimCreator.locationLabel}</p>
                            </div>
                        </div>
                    )}
                </td>
                <td>
                    {claim.customerName}{' '}
                    {claim.type === 'stock-item' && (
                        <span style={{ opacity: 0.3 }}>– – – – – – – –</span>
                    )}
                </td>
                <td>{claim.status}</td>
                <td>{claim.supplierName || claim.haulerName}</td>
                <td>
                    <Icon
                        type={
                            'checkbox' +
                            (claim.checklist && claim.checklist.stockUpdated ? '-checked' : '')
                        }
                    />
                </td>
                <td>{claim.checklist && claim.checklist.baNumber}</td>
                {/*hasPermission(this.props.currentUser, roles.Personalleitung, roles.Verkauf, roles.Verwaltung, roles.Filialleitung) && (
                <>
                    <td>
                        <Icon
                            type={
                                'checkbox' +
                                (claim.checklist && claim.checklist.stockUpdated ? '-checked' : '')
                            }
                        />
                    </td>
                    <td>{claim.checklist && claim.checklist.baNumber}</td>
                </>
                        )*/}
            </tr>
        );
    };

    generateHeaderExtra = (id) => (
        <FormInput
            placeholder="Suche"
            type="text"
            label=""
            name="filter_number"
            id={id}
            value={this.state.filter.number}
            onChange={(ev) => this.handleNumberFilterChange(ev.target.value)}
        />
    );

    render() {
        const cardFooter = (
            <Button
                label="Mehr laden"
                inverted={true}
                onClick={() =>
                    this.props.loadMoreOpenClaims({
                        number: this.state.filter.number,
                        year: this.props.yearFilter,
                    })
                }
                disabled={this.props.countOpen <= this.props.openList.length}
            />
        );

        return (
            <>
                <ClaimForm
                    claimId={this.state.claimToEdit}
                    isOpen={this.state.showForm}
                    onCancel={this.closeForm}
                />
                <CardGrid className="Claims">
                    <Card
                        icon="claim"
                        title="Offene Beanstandungen"
                        header_extra={this.generateHeaderExtra('card1')}
                        footer={cardFooter}
                        className="overflow-visible"
                    >
                        <table className="claims__list">
                            <thead>
                                <tr>
                                    <th onClick={() => this.updateSorting('number')}>
                                        Nummer {this.showSorting('number')}
                                    </th>
                                    <th onClick={() => this.updateSorting('createdAt')}>
                                        Datum {this.showSorting('createdAt')}
                                    </th>
                                    <th onClick={() => this.updateSorting('createdBy')}>
                                        Angelegt von {this.showSorting('createdBy')}
                                    </th>
                                    <th onClick={() => this.updateSorting('customerName')}>
                                        Kunde {this.showSorting('customerName')}
                                    </th>
                                    <th onClick={() => this.updateSorting('status')}>
                                        Status {this.showSorting('status')}
                                    </th>
                                    <th onClick={() => this.updateSorting('supplierName')}>
                                        Hersteller / Spediteur {this.showSorting('supplierName')}
                                    </th>
                                    <th
                                        onClick={() => this.updateSorting('checklist.stockUpdated')}
                                    >
                                        Bestandskorrektur <br />
                                        gebucht {this.showSorting('checklist.stockUpdated')}
                                    </th>
                                    <th onClick={() => this.updateSorting('checklist.baNumber')}>
                                        BA-Nummer {this.showSorting('checklist.baNumber')}
                                    </th>
                                    {/*hasPermission(this.props.currentUser, roles.Personalleitung, roles.Verwaltung, roles.Mitarbeiter, roles.Filialleitung) && (
                                    <>
                                        <th>
                                            Bestandskorrektur
                                            <br />
                                            gebucht
                                        </th>
                                        <th>BA-Nummer</th>
                                    </>
                                )*/}
                                </tr>
                            </thead>
                            <tbody>{this.renderClaims(this.props.openList)}</tbody>
                        </table>
                    </Card>
                    <Card
                        icon="claim"
                        title="Erledigte Beanstandungen"
                        header_extra={this.generateHeaderExtra('card2')}
                        footer={
                            <Button
                                label="Mehr laden"
                                inverted={true}
                                onClick={() =>
                                    this.props.loadMoreCompletedClaims({
                                        number: this.state.filter.number,
                                        year: this.props.yearFilter,
                                    })
                                }
                                disabled={this.props.countClosed <= this.props.closedList.length}
                            />
                        }
                    >
                        <table className="claims__list">
                            <thead>
                                <tr>
                                    <th onClick={() => this.updateSorting('number')}>
                                        Nummer {this.showSorting('number')}
                                    </th>
                                    <th onClick={() => this.updateSorting('createdAt')}>
                                        Datum {this.showSorting('createdAt')}
                                    </th>
                                    <th onClick={() => this.updateSorting('createdBy')}>
                                        Angelegt von {this.showSorting('createdBy')}
                                    </th>
                                    <th onClick={() => this.updateSorting('customerName')}>
                                        Kunde {this.showSorting('customerName')}
                                    </th>
                                    <th onClick={() => this.updateSorting('status')}>
                                        Status {this.showSorting('status')}
                                    </th>
                                    <th onClick={() => this.updateSorting('supplierName')}>
                                        Hersteller / Spediteur {this.showSorting('supplierName')}
                                    </th>
                                    <th
                                        onClick={() => this.updateSorting('checklist.stockUpdated')}
                                    >
                                        Bestandskorrektur <br />
                                        gebucht {this.showSorting('checklist.stockUpdated')}
                                    </th>
                                    <th onClick={() => this.updateSorting('checklist.baNumber')}>
                                        BA-Nummer {this.showSorting('checklist.baNumber')}
                                    </th>
                                    {/*hasPermission(this.props.currentUser, roles.Personalleitung, roles.Mitarbeiter, roles.Verwaltung, roles.Filialleitung) && (
                                    <>
                                        <th>
                                            Bestandskorrektur
                                            <br />
                                            gebucht
                                        </th>
                                        <th>BA-Nummer</th>
                                    </>
                                )*/}
                                </tr>
                            </thead>
                            <tbody>{this.renderClaims(this.props.closedList)}</tbody>
                        </table>
                        {this.can('claim_can_export') && (
                            <div className="content-wrapper inner-ver">
                                <div className="content-wrapper-box">
                                    <div className="grid-wrapper">
                                        <div className="small-12 medium-12 large-12">
                                            <div>
                                                <br />
                                                <form
                                                    action={this.getLink()}
                                                    method="post"
                                                    ref={(ref) => {
                                                        this.downloadForm = ref;
                                                    }}
                                                >
                                                    <input
                                                        type="hidden"
                                                        name="authTok"
                                                        value={'Bearer ' + this.props.token}
                                                    />
                                                </form>
                                                <IconLink
                                                    type="download"
                                                    onClick={() => this.downloadForm.submit()}
                                                >
                                                    {' '}
                                                    Export XLSX
                                                </IconLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Card>
                </CardGrid>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: state.data.currentUser,
        openClaims: selectors.Claims.openClaims(state, ownProps.locationFilter),
        completedClaims: selectors.Claims.completedClaims(state, ownProps.locationFilter),
        openClaimsListIsLoading: state.claims.openClaimsListIsLoading,
        completedClaimsListIsLoading: state.claims.completedClaimsListIsLoading,
        yearFilter: state.data.yearFilter,
        sorting: state.claims.sorting,
        loadAnimation: state.claims.loadAnimation,
        openList: state.claims.openList,
        closedList: state.claims.closedList,
        countClosed: state.claims.countClosed,
        countOpen: state.claims.countOpen,
        token: state.ui.token,
        userPopup: state.data.userPopup,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        reloadOpenClaims: (filter) => {
            return dispatch(
                actions.Claims.loadOpenList({
                    filter: {
                        completed: false,
                        location: ownProps.locationFilter,
                        ...filter,
                    },
                    forceReload: ownProps.forceReload || filter.forceReload,
                    fromNumber: 0,
                })
            );
        },
        reloadCompletedClaims: (filter) =>
            dispatch(
                actions.Claims.loadClosedList({
                    filter: {
                        completed: true,
                        location: ownProps.locationFilter,
                        ...filter,
                    },
                    forceReload: ownProps.forceReload || filter.forceReload,
                    fromNumber: 0,
                })
            ),
        loadMoreOpenClaims: (filter) =>
            dispatch(
                actions.Claims.loadOpenList({
                    filter: {
                        completed: false,
                        location: ownProps.locationFilter,
                        ...filter,
                    },
                })
            ),
        loadMoreCompletedClaims: (filter) =>
            dispatch(
                actions.Claims.loadClosedList({
                    filter: {
                        completed: true,
                        location: ownProps.locationFilter,
                        ...filter,
                    },
                })
            ),
        updateSorting: (value) => dispatch(actions.Claims.updateSorting(value)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(ClaimList);
