import DateCheckbox from 'Components/UI/Form/DateCheckbox';
import FormikInput from 'Components/UI/Form/FormikInput';
import FileInput from 'Components/UI/Form/FileInput';
import Select from 'Components/UI/Form/Select';
import { Field } from 'formik';
import * as PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {actions} from "../../Redux";

const mapStateToProps = (state) => {
    return {
        currentUser: state.data.currentUser,
        locationList: state.data.allLocations,
        articleDropdownFilter: state.data.articleDropdownFilter,
        articleDropdownList: state.data.articleDropdownList,
        articleDropdown: state.claims.articleDropdown,
        claimDropdownManufacturers: state.data.claimDropdownManufacturers,
        claimDropdownReasons: state.data.claimDropdownReasons,
        claimDropdownQualities: state.data.claimDropdownQualities,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateArticleDropdownFilter: (filter) => dispatch(actions.Data.updateArticleDropdownFilter(filter)),
        updateArticleDropdown: dropdown => dispatch(actions.Claims.updateArticleDropdown(dropdown)),
    }
}

class ClaimChecklist extends React.Component {
    static propTypes = {
        onUpdate: PropTypes.func,
        isAccounting: PropTypes.bool,
    };

    state = {
        editable: false,
        dirty: false,
        type: '',
        checkbox: undefined,
    };


    displayFunc2 = (dateString) => {
        return dateString && this.props.values.checklist.scenario ? moment(dateString).format('dd., DD. MMM YYYY, H:mm [Uhr]') : '';
    };

    toNumber = (number) => {
        if(number !== '' ) {
            return Number(number.toString().replace(/,/g, "."));
        }
        return number
    };



    render() {
        const checklist = this.props.values ? this.props.values.checklist : false;

        return (
            <div className="content-wrapper content-form">
                <div className="content-wrapper-box">
                    <div className="grid-wrapper">
                        <FormikInput
                            label="Szenario"
                            name="checklist.scenario"
                            readOnly={checklist ? !!checklist.completed : false}
                            component={Select}
                            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 'abgelehnt']}
                            labelField="value"
                            size="medium"
                        />
                        <FormikInput
                            label="Beanstandungsbogen"
                            name="checklist.complaintForm"
                            readOnly={!!checklist.completed}
                            component={FileInput}
                            accept=".pdf, .png, .jpg, .jpeg, .heic, .heif, .mp4, .hevc, .mov, .mov.qt"
                            size="medium"
                        />
                        <FormikInput
                            label="Bilder & Video"
                            name="checklist.pictures"
                            readOnly={!!checklist.completed}
                            component={FileInput}
                            accept=".pdf, .png, .jpg, .jpeg, .heic, .heif, .mp4, .hevc, .mov, .mov.qt"
                            multiple={true}
                            size="large"
                        />
                            <FormikInput
                                label="Kundenrechnung & Stellungnahme"
                                name="checklist.customerInvoiceUploads"
                                readOnly={!!checklist.completed}
                                component={FileInput}
                                accept=".pdf, .png, .jpg, .jpeg, .heic, .heif, .mp4, .hevc, .mov, .mov.qt"
                                multiple={true}
                                size="large"
                            />
                        <FormikInput
                            label={this.props.values.type === 'hauler-item' ? 'Reklamationsnummer Spedition' : 'Reklamationsnummer Hersteller'}
                            name="checklist.supplierClaimNumber"
                            readOnly={!!checklist.completed || !checklist.forwardedToSupplier}
                            size="small-12 medium-12 large-6"
                        />

                        <FormikInput
                            label={this.props.values.type === 'hauler-item' ? 'Weiterleitung an Spedition' : 'Weiterleitung an Industrie'}
                            name="checklist.forwardedToSupplier"
                            readOnly={!!checklist.completed}
                            component={DateCheckbox}
                            size="small-12 medium-12 large-6"
                        />
                        <FormikInput
                            label="Beanstandung anerkannt"
                            name="checklist.acceptedBySupplier"
                            readOnly={!!checklist.completed || !checklist.forwardedToSupplier}
                            component={DateCheckbox}
                            size="small-12 medium-12 large-6"
                        />
                        <div className="small-12 medium-0 large-0" />
                        <FormikInput
                            label={"Kulanz"}
                            name="checklist.goodWill"
                            readOnly={!!checklist.completed}
                            size="small-12 medium-12 large-6"
                        />
                        <FormikInput
                            label={"E-Mail-Adresse"}
                            name="checklist.email"
                            readOnly={!!checklist.completed}
                            component={'input'}
                            size="small-12 medium-12 large-6"
                            type={"email"}
                        />
                        <FormikInput
                            label={"Coupon/Gutschein-Code"}
                            name="checklist.coupon"
                            readOnly={!!checklist.completed}
                            component={'input'}
                            size="small-12 medium-12 large-6"
                        />
                        <FormikInput
                            label="Belege nach Leitfaden bearbeitet & fotografiert"
                            name="checklist.workflowDone"
                            readOnly={!!checklist.completed && !!checklist.scenario}
                            component={DateCheckbox}
                            size="large"
                        />
                        <div className="small-12 medium-12 large-12">{this.renderCloze()}</div>
                        <FormikInput
                            label="Zusatzinformation"
                            name="checklist.additionalInfo"
                            readOnly={!!checklist.completed}
                            rows="2"
                            type="text"
                            size="large"
                            className="test"
                            component="textarea"
                            maxLength="10000"
                        />
                        <FormikInput
                            label="Beanstandung abgeschlossen"
                            name="checklist.completed"
                            readOnly={!(this.props.isAccounting || (!checklist.completed)) && checklist.scenario }
                            component={DateCheckbox}
                            displayFunc={this.displayFunc2}
                            title={checklist.scenario ? 'yes': 'no'}
                            size="large"
                        />
                        {this.props.isAccounting && (
                            <>
                            {/*
                                <FormikInput
                                    label="Bestandskorrektur gebucht"
                                    name="checklist.stockUpdated"
                                    component={DateCheckbox}
                                    readOnly={!checklist.completed}
                                    size="medium"
                                />*/}
                                {/*
                                <FormikInput
                                    label="BA-Nummer"
                                    name="checklist.baNumber"
                                   readOnly={!checklist.completed}
                                    size="medium"
                                />*/}
                                <FormikInput
                                    label="Bestandskorrektur gebucht"
                                    name="checklist.stockUpdated"
                                    component={DateCheckbox}
                                    readOnly={!(this.props.isAccounting || !checklist.completed)}
                                    size="medium"
                                />
                                <FormikInput
                                    label="BA-Nummer"
                                    name="checklist.baNumber"
                                    readOnly={!(this.props.isAccounting || !checklist.completed)}
                                    size="medium"
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    getSuplierValue = (additional, index) => {
        if (additional && this.props.values.checklist.lueckentext.additionalArticles) {
            if (this.props.values.checklist.lueckentext.additionalArticles[index]) {
                return this.props.values.checklist.lueckentext.additionalArticles[index].supplierName
            }
        }
        return this.props.values.checklist.lueckentext.supplierName || this.props.values.supplierName || ''
    }
    componentDidMount() {
        this.props.updateArticleDropdown({ view: false, index:0})
        setTimeout(() => {
            if(!this.props.values.checklist.lueckentext.supplierName) {
                this.props.setFieldValue(
                    `checklist.lueckentext.supplierName` ,
                    this.props.values.supplierName,
                    false
                )}
        }, 10)

    }
    getPreselectedOption = (additional, index, fieldName) => {
        if (additional) {
            if (this.props.values.checklist.lueckentext.additionalArticles.length) {
                return (<option
                    value={this.props.values.checklist.lueckentext.additionalArticles[index] ? this.props.values.checklist.lueckentext.additionalArticles[index][fieldName] : ''}>
                    {this.props.values.checklist.lueckentext.additionalArticles[index] ? this.props.values.checklist.lueckentext.additionalArticles[index][fieldName] : ''}
                </option>)
            } else {
                return ''
            }
        }
        return (
            <option value={this.props.values.checklist.lueckentext[fieldName] || ''}>
                {this.props.values.checklist.lueckentext[fieldName] || ''}
            </option>
        )
    }

    renderSingleArticle = (readOnly, index, additional) => {
        const name = additional ? `checklist.lueckentext.additionalArticles[${index}]` : 'checklist.lueckentext';

        return (
            <React.Fragment key={index}>
                {additional && <hr/>}
                <div className="claimChecklistWrapper">
            <ul >
                <li>
                    Artikelnummer{' '}
                    <Field
                        name={`${name}.articleNumber`}
                        readOnly={readOnly}
                        onKeyUp={(e) =>  {
                            this.props.updateArticleDropdownFilter({
                                prop: 'number',
                                value: e.target.value,
                            });
                            this.props.updateArticleDropdown({
                                view: true,
                                index,
                                additional
                            })
                        }}
                    />
                    {this.props.articleDropdown.view &&
                    this.props.articleDropdown.index === index &&
                    this.props.articleDropdown.additional === additional &&

                    <div className="check-list-article-dropdown">
                        <ul>
                            {!this.props.articleDropdownList.length &&
                            <li>
                              <span onClick={e => {
                                  this.props.updateArticleDropdown({ view: false, index:0})
                              }
                              }>Keine passende Artikel gefunden <i className="icon fa icon--clickable fa-times-circle-o" style={{fontSize: '20px'}}></i></span>
                            </li>
                            }
                            {this.props.articleDropdownList.map(article => (
                                <li key={article.id}>
                                        <span onClick={e => {
                                            this.props.updateArticleDropdown({
                                                view: false,
                                                index
                                            })
                                            // this.props.form.setFieldValue(`${name}.supplierName`, article.manufacturer.name)
                                            // this.props.setFieldValue(`${name}.supplierName`, article.manufacturerName, false)
                                            // this.props.setFieldValue(`${name}.quality`, article.quality, false)
                                            this.props.setFieldValue(`${name}.articleNumber`, article.number, false)
                                            this.props.setFieldValue(`${name}.articleName`, article.decor, false)
                                        }}
                                        >{article.manufacturerQualityAndDecor}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                    }
                    <i className="fa fa-search claimSearchIcon" aria-hidden="true" title={"Geben Sie eine Artikelnummer ein, um die folgenden Felder automatisch auszufüllen."}></i>
                </li>
                {/*
                <li className="dropdown-select">
                    Hersteller{' '}
                    <Field
                        name={`${name}.supplierName`}
                        disabled={readOnly}
                        component="select"
                    >
                        {this.getPreselectedOption(additional, index, 'supplierName')}
                        {this.props.claimDropdownManufacturers.map(manufacturer => {
                            return (
                                <option key={manufacturer.id} value={manufacturer.name}>{manufacturer.name}</option>
                            )
                        })}

                    </Field>
                </li>
                */}

                <li>
                    Hersteller{' '}
                    <Field  name={`${name}.supplierName`} value={this.props.values.supplierName} readOnly={true} />

                </li>

                <li className="dropdown-select">
                    Qualität{' '}
                    <Field
                        name={`${name}.quality`}
                        disabled={readOnly}
                        component="select"
                    >
                        {this.getPreselectedOption(additional, index, 'quality')}
                        {this.props.claimDropdownQualities.map(quality => {
                            return (
                                <option key={quality.id} value={quality.name}>{quality.name}</option>
                            )
                        })}

                    </Field>
                </li>
                {/*
                    <li>
                    <label>
                        Qualität{' '}
                        <Field name={`${name}.quality`} readOnly={readOnly} />
                    </label>
                    </li>
                */}


                <li>
                    Artikelbezeichnung{' '}
                    <Field name={`${name}.articleName`} readOnly={readOnly} />
                </li>
                <li>
                    Paketinhalt{' '}
                    <Field
                        name={`${name}.packageContent`}
                        readOnly={readOnly}
                        onKeyUp={(value, e) => {
                            this.props.setFieldValue(`${name}.surfaceSize`,
                                additional ?
                                    (this.toNumber(this.props.values.checklist.lueckentext.additionalArticles[index].packageContent) *
                                        this.toNumber(this.props.values.checklist.lueckentext.additionalArticles[index].packageCount)).toFixed(3) :
                                    (this.toNumber(this.props.values.checklist.lueckentext.packageContent) *
                                        this.toNumber(this.props.values.checklist.lueckentext.packageCount)).toFixed(3),
                                false
                            )
                        }}
                    />
                </li>
                <li>
                    Paketanzahl{' '}
                    <Field
                        name={`${name}.packageCount`}
                        type="number"
                        min={0}
                        readOnly={readOnly}
                        onKeyUp={(value, e) => {
                            this.props.setFieldValue(`${name}.surfaceSize`,
                                additional ?
                                    (this.toNumber(this.props.values.checklist.lueckentext.additionalArticles[index].packageContent) *
                                    this.toNumber(this.props.values.checklist.lueckentext.additionalArticles[index].packageCount)).toFixed(3) :
                                    (this.toNumber(this.props.values.checklist.lueckentext.packageContent) *
                                        this.toNumber(this.props.values.checklist.lueckentext.packageCount)).toFixed(3),
                                false
                            )
                        }}
                    />
                </li>
                <li>
                    Gesamt m²{' '}
                    <Field
                        name={`${name}.surfaceSize`}
                        type="number"
                        min={0}
                        readOnly={readOnly}
                    />
                </li>
                <li className="dropdown-select">
                    Grund{' '}
                    <Field
                        name={`${name}.reason`}
                        disabled={readOnly}
                        component="select"
                    >
                        {this.getPreselectedOption(additional, index, 'reason')}
                        {this.props.claimDropdownReasons.map(reason => {
                            return (
                                <option key={reason.id} value={reason.name}>{reason.name}</option>
                            )
                        })}

                    </Field>
                </li>
                {/*
                                    <li>
                    Grund <Field name={`${name}.reason`} readOnly={readOnly} />
                </li>
                */}


            </ul>

                <div>
                    {!readOnly && additional && this.renderDeleteButton(index)}
                </div>
            </div>
            </React.Fragment>
        )
    }



    renderDeleteButton(index) {
        return (
            <button type="button"
                    className="remove-button"
                    onClick={() => {
                        const r = window.confirm("Artikel entfernen?");
                        if (r !== true) {
                            return
                        }

                        setTimeout(() => {
                            const withoutDeleted = this.props.values.checklist.lueckentext.additionalArticles.filter((art, i) => index !== i)
                            this.props.setFieldValue(
                                `checklist.lueckentext.additionalArticles` ,
                                withoutDeleted,
                                false
                            )
                        }, 50)
                    }}
            ><i className="fa fa-trash"/></button>
        )
    }

    renderAddButton() {
        return (
            <div className="addButtonWrapper">
                <button type="button"
                        className="add-button"
                        onClick={() => {
                            const index = this.props.values.checklist.lueckentext.additionalArticles ?
                                this.props.values.checklist.lueckentext.additionalArticles.length : 0
                            this.props.setFieldValue(
                                `checklist.lueckentext.additionalArticles[${index}]`,
                                {
                                    'articleNumber': '',
                                    'supplierName': '',
                                    'articleName': '',
                                    'quality': '',
                                    'surfaceSize': '',
                                    'packageContent': '',
                                    'packageCount': '',
                                    'reason': ''
                                },
                                false
                            )
                        }}
                ><span>Artikel hinzufügen</span> <i type="add" className="icon fa fa-plus" role="presentation"></i></button>
            </div>
        )
    }

    renderArticles(readOnly) {
        return (
            <>
                {this.renderSingleArticle(readOnly, 0, false)}
                {this.props.values.checklist.lueckentext.additionalArticles &&
                    this.props.values.checklist.lueckentext.additionalArticles.map((article, index) => {
                    return this.renderSingleArticle(readOnly, index, true)

                })}
                {!readOnly && this.renderAddButton()}
            </>

        )
    }

    renderCloze = () => {
        const readOnly = this.props.values.checklist.completed;
        return (
            <div className="claims-form__cloze">
                <p>
                    Bitte um eine Belastungsanzeige zur Beanstandungsnummer{' '}
                    <Field name="checklist.lueckentext.complaintNumber" readOnly={readOnly} /> über:
                </p>
                {this.renderArticles(readOnly)}
                <p>
                    {this.props.values.type === 'hauler-item' ? 'Die Reklamationsnummer des Spediteurs lautet: ' : 'Die Reklamationsnummer des Herstellers lautet: '}
                    <input
                        value={this.props.values.checklist.supplierClaimNumber || ''}
                        readOnly={true}
                    />
                    .
                    <br />
                    Was soll mit der Reklamationsware passieren?{' '}
                    <Field name="checklist.lueckentext.treatment" readOnly={readOnly} />.
                </p>

                <p>
                    Der Bestand muss{' '}
                    <Field
                        name="checklist.lueckentext.stockNeedsUpdate"
                        disabled={readOnly}
                        component="select"
                    >
                        <option value="">...</option>
                        <option value="true">korrigiert werden</option>
                        <option value="false">nicht korrigiert werden</option>
                    </Field>
                    .
                </p>

                <p>
                    IC Bestellung muss{' '}
                    <Field
                        name="checklist.lueckentext.stockNeedsLocation"
                        disabled={readOnly}
                        component="select"
                    >
                        <option value="">...</option>
                        <option value="true">stattfinden</option>
                        <option value="false">nicht stattfinden</option>
                    </Field>
                    {' '}in Filiale{' '}
                    <Field
                        name="checklist.lueckentext.stockLocation"
                        disabled={readOnly}
                        component="select"
                    >
                        <option value="">...</option>
                        {this.props.locationList.map(location => <option key={location.id} value={location.label}>{location.label}</option>)}
                    </Field>
                    .
                </p>

                <p>
                    Im Anhang findest du die dazugehörigen Unterlagen. Für mich bitte eine Kopie der
                    Belastungsanzeige.
                    <br /> Danke ☺️
                </p>

                <p>
                    Mit freundlichen Grüßen
                    <br />
                    <Field name="checklist.lueckentext.name" readOnly={readOnly} />
                </p>
            </div>
        );
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(ClaimChecklist);
